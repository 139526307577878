<script setup lang="ts">
import { pascalCase } from "scule";
import {
  getCmsLayoutConfiguration,
  getBackgroundImageUrl,
} from "@shopware-pwa/helpers-next";
import { useNavigationContext, createCategoryListingContext } from "#imports";
import { computed, h, resolveComponent } from "vue";
import type { Schemas } from "#shopware";

const props = defineProps<{
  content: Schemas["CmsPage"];
}>();
const attrs = useAttrs();

const { routeName } = useNavigationContext();
if (routeName.value === "frontend.navigation.page") {
  createCategoryListingContext();
}

const cmsSections = computed<Schemas["CmsSection"][]>(() => {
  return props.content?.sections || [];
});

const DynamicRender = () => {
  const componentsMap = cmsSections.value.map((section) => {
    return {
      name: `CmsSection${pascalCase(section.type)}`,
      component: resolveComponent(`CmsSection${pascalCase(section.type)}`),
      section: section,
    };
  });

  return componentsMap.map((componentObject) => {
    if (typeof componentObject.component === "string") {
      return h("div", {}, `There is no ${componentObject.component}`);
    }

    const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(
      componentObject.section,
    );

    const backgroundImage = layoutStyles?.backgroundImage
      ? getBackgroundImageUrl(
          layoutStyles.backgroundImage,
          componentObject.section,
        )
      : null;

    const styleProps = {
      backgroundColor: layoutStyles?.backgroundColor,
      backgroundImage: backgroundImage,
      ...(backgroundImage && { backgroundSize: layoutStyles?.backgroundSize }),
    };

    const filteredStyleProps = Object.fromEntries(
      Object.entries(styleProps).filter(([_, value]) => value != null),
    );

    const componentProps = {
      content: componentObject.section,
      class: attrs.class ?? {
        ...cssClasses,
        "max-w-screen-3xl mx-auto": layoutStyles?.sizingMode === "boxed",
      },
      style:
        (attrs.style ?? Object.keys(filteredStyleProps).length > 0)
          ? filteredStyleProps
          : undefined,
    };

    return h(componentObject.component, componentProps);
  });
};
</script>

<template>
  <DynamicRender />
</template>
